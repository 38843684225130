// Fallback to loading first image if we pop an unexpected state. This will be the original page load
window.onpopstate = function(event) {
    var hashDetail = {
        groupKey: 0,
        visualKey: 0,
        image: 'standard'
    };
    if (event.state !== null) {
        hashDetail = v_project.imageHashes[event.state.imageHash];
    }
    v_project.loadVisual(hashDetail.groupKey, hashDetail.visualKey, hashDetail.image, false);
};

var v_project = new Vue({
    el: '#project',
    data: {
        sidebar: true,
        customerSlug: null,
        projectSlug: null,
        initial_image: null,
        visualIsLoading: false,
        visualIsLoadingTimeout: null,
        // The requested visual & image
        active: {
            group: null,
            visual: null,
            size: null,
            height: '100%',
            date: null,
            filesize: null,
            download: null,
            downloadGroup: null,
            group_visuals: null,
            group_size: null,
        },
        // The styles applied for the active visual (current and upcoming)
        views: {
            tickOrTock: null,
            tickStyle: {},
            tockStyle: {}
        },
        // All of the project data
        visualGroups: [],
        imageHashes: []
    },
    computed: {
        multiViews: function() {
            var numImages = 0;
            if (this.visualGroups[this.active.group].visuals[this.active.visual].images.standard.url !== false) {
                numImages++;
            }
            if (this.visualGroups[this.active.group].visuals[this.active.visual].images.small.url !== false) {
                numImages++;
            }
            if (this.visualGroups[this.active.group].visuals[this.active.visual].images.mini.url !== false) {
                numImages++;
            }
            return numImages > 1;
        }
    },
    ready: function() {
        this.fetchVisuals();
    },
    methods: {
        toggleSidebar: function() {
            this.sidebar = !this.sidebar;
        },
        fetchVisuals: function() {
            var that = this;
            $.ajax({
                url: '/' + this.customerSlug + '/' + this.projectSlug + '/list',
                success: function(response) {
                    that.visualGroups = response.visualGroups;
                    that.imageHashes = response.imageHashes;
                    var initialGroup = 0;
                    var initialVisual = 0;
                    if (that.initial_image !== "") {
                        initialGroup = response.imageHashes[that.initial_image].groupKey;
                        initialVisual = response.imageHashes[that.initial_image].visualKey;
                    }
                    that.loadVisual(initialGroup, initialVisual, 'standard', false);
                }
            });
        },
        hopVisual: function(mode) {
            if (this.visualGroups[this.active.group].visuals.length == 1) {
                return;
            }

            // Target visual (stay within the current group)
            var newIndex = this.active.visual;
            if (mode == 'next') {
                newIndex += 1;
            } else if (mode == 'previous') {
                newIndex -= 1;
            }

            // Wrap when reaching the first/last visual
            if (newIndex >= this.visualGroups[this.active.group].visuals.length) {
                newIndex = 0;
            }
            if (newIndex < 0) {
                newIndex = this.visualGroups[this.active.group].visuals.length - 1;
            }

            this.loadVisual(this.active.group, newIndex, 'standard');
        },
        loadVisual: function(groupKey, visualKey, size, recordHistory) {

            if (this.active.group == groupKey && this.active.visual == visualKey && this.active.size == size) {
                return;
            }

            this.active.group = groupKey;
            this.active.visual = visualKey;

            var that = this;

            this.visualIsLoadingTimeout = window.setTimeout(function() {
                that.visualIsLoading = true;
            }, 250);

            // If this size isn't available, choose another (prioritise biddest first)
            if (this.visualGroups[groupKey].visuals[visualKey].images[size].url === false) {
                if (this.visualGroups[groupKey].visuals[visualKey].images['standard'].url !== false) {
                    size = 'standard';
                } else if (this.visualGroups[groupKey].visuals[visualKey].images['small'].url !== false) {
                    size = 'small';
                } else if (this.visualGroups[groupKey].visuals[visualKey].images['mini'].url !== false) {
                    size = 'mini';
                }
            }
            this.active.size = size;

            if (typeof recordHistory === 'undefined') {
                recordHistory = true;
            }

            this.active.group_visuals = 0;
            this.active.group_size = 0;
            var groupSize = 0;
            for (var groupVisualKey in this.visualGroups[groupKey].visuals) {
                for (var imageKey in this.visualGroups[groupKey].visuals[groupVisualKey].images) {
                    if (typeof this.visualGroups[groupKey].visuals[groupVisualKey].images[imageKey].filesize !== 'undefined') {
                        this.active.group_visuals++;
                        this.active.group_size += this.visualGroups[groupKey].visuals[groupVisualKey].images[imageKey].filesize_bytes;
                    }
                }
            }
            this.active.group_size /= (1024 * 1024);
            this.active.group_size = (Math.round(this.active.group_size * 10) / 10) + 'MB';

            // Preload the new image
            var img = new Image();
            img.src = this.visualGroups[groupKey].visuals[visualKey].images[size].url;
            img.onload = function() {

                // Info
                that.active.date = that.visualGroups[groupKey].visuals[visualKey].images[size].date;
                that.active.filesize = that.visualGroups[groupKey].visuals[visualKey].images[size].filesize;
                that.active.download = that.visualGroups[groupKey].visuals[visualKey].images[size].url + '/download';
                that.active.downloadGroup = that.visualGroups[groupKey].visuals[visualKey].images[size].url + '/download-group';

                // Set tick and tock to the height of the new visual
                that.active.height = that.visualGroups[groupKey].visuals[visualKey].images[size].height + 'px';

                // Set the style and switch the active view
                var style = {
                    height: that.active.height,
                    backgroundImage: 'url(' + that.visualGroups[groupKey].visuals[visualKey].images[size].url + ')',
                    backgroundPosition: 'top ' + that.visualGroups[groupKey].visuals[visualKey].align,
                    backgroundColor: that.visualGroups[groupKey].visuals[visualKey].background
                };
                if (that.views.tickOrTock == 'tick') {
                    that.views.tockStyle = style;
                    that.views.tickStyle.height = that.active.height;
                    that.views.tickOrTock = 'tock';
                } else {
                    that.views.tickStyle = style;
                    that.views.tockStyle.height = that.active.height;
                    that.views.tickOrTock = 'tick';
                }

                // Sidebar height hack (visuals and sidebar need to be the same height)
                $(".sidebar").height('auto');
                var imageHeight = parseInt(that.active.height.substr(0, that.active.height.length - 2));
                var sidebarHeight = $(".sidebar").height();
                if (imageHeight > sidebarHeight) {
                    $(".sidebar").height(imageHeight);
                } else {
                    that.views.tockStyle.height = sidebarHeight + "px";
                    that.views.tickStyle.height = sidebarHeight + "px";
                }

                // Update the URL
                if (recordHistory === true && history.pushState) {
                    var hash = that.visualGroups[groupKey].visuals[visualKey].images[size].hash;
                    var state = {
                        imageHash: hash
                    };
                    var title = '';
                    var url = '/' + that.customerSlug + '/' + that.projectSlug + '/' + hash;
                    history.pushState(state, title, url);
                }

                window.clearTimeout(that.visualIsLoadingTimeout);
                that.visualIsLoading = false;

            }

        }
    }
});
